/*
 |--------------------------------------------------------------------------
 | @package  craft-baukasten
 | @author David Hellmann [david@hellmann.io]
 |
 | .a-richText
 |--------------------------------------------------------------------------
 */

.c-richText {
  max-width: 80ch;
  pointer-events: none;
  user-select: none;

  a {
    user-select: all;
    pointer-events: all !important;
  }

  .ms-1 {
    font-weight: 400;
    @include wf('impact');
    margin-bottom: -3rem;
  }

  ul > li + li {
    margin-top: 3rem;
  }

  ol a {
    display: block;
  }
}

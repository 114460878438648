/**
 * components/webfonts.css
 *
 * Project webfonts.
 *
 */

@import url("//hello.myfonts.net/count/3b433d");

@font-face {
  font-family: 'ImpactURW';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local('Impact'),
  url('/dist/fonts/font.woff2') format('woff2'),
  url('/dist/fonts/font.woff') format('woff');
}


/*
@font-face {
  font-family: 'Merriweather';
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: local('Merriweather Black'), local('Merriweather-Black'),
  url('~typeface-merriweather/files/merriweather-latin-900.woff2') format('woff2'),
  url('~typeface-merriweather/files/merriweather-latin-900.woff') format('woff');
}

@font-face {
  font-family: 'Merriweather';
  font-display: swap;
  font-style: italic;
  font-weight: 900;
  src: local('Merriweather Black Italic'), local('Merriweather-BlackItalic'),
  url('~typeface-merriweather/files/merriweather-latin-900italic.woff2') format('woff2'),
  url('~typeface-merriweather/files/merriweather-latin-900italic.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'),
  url('~typeface-lato/files/lato-latin-300.woff2') format('woff2'),
  url('~typeface-lato/files/lato-latin-300.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  src: local('Lato Light Italic'), local('Lato-LightItalic'),
  url('~typeface-lato/files/lato-latin-300italic.woff2') format('woff2'),
  url('~typeface-lato/files/lato-latin-300italic.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'),
  url('~typeface-lato/files/lato-latin-700.woff2') format('woff2'),
  url('~typeface-lato/files/lato-latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-display: swap;
  font-style: italic;
  font-weight: 700;
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
  url('~typeface-lato/files/lato-latin-700italic.woff2') format('woff2'),
  url('~typeface-lato/files/lato-latin-700italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Mono'), local('RobotoMono-Regular'),
  url('~typeface-roboto-mono/files/roboto-mono-latin-400.woff2') format('woff2'),
  url('~typeface-roboto-mono/files/roboto-mono-latin-400.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Mono Bold'), local('RobotoMono-Bold'),
  url('~typeface-roboto-mono/files/roboto-mono-latin-700.woff2') format('woff2'),
  url('~typeface-roboto-mono/files/roboto-mono-latin-700.woff') format('woff');
}
*/

/*
 * Colors
 */

:root {
  --c-black: #000;
  --c-white: #fff;
  --c-gray: #A0AEC0;
  --c-gray-100: #F7FAFC;
  --c-gray-200: #EDF2F7;
  --c-gray-300: #E2E8F0;
  --c-gray-400: #CBD5E0;
  --c-gray-500: #A0AEC0;
  --c-gray-600: #718096;
  --c-gray-700: #4A5568;
  --c-gray-800: #2D3748;
  --c-gray-900: #1A202C;
  --c-red: #E53E3E;
  --c-green: #38A169;
  --c-blue: #3182CE;
  --c-purple: #6620ff;
}

/*
 |--------------------------------------------------------------------------
 | @package  craft-baukasten
 | @author David Hellmann [david@hellmann.io]
 |
 | .c-cursor
 |--------------------------------------------------------------------------
 */

* {
  cursor: none !important;
}

.c-cursor {
  left: -50px;
  top: -50px;
  transform: translate(-100%, -100%);
  z-index: 1600000;
  position: fixed;
  opacity: 1;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: opacity 250ms 250ms ease-out;

  &.is-clicked {
    opacity: 0;
    transition: opacity 250ms 0ms ease-out;
  }

  @media (pointer: coarse) {
    display: none !important;
  }
}

.c-cursor__inner {
  position: relative;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 5px solid var(--c-purple);
  background-color: var(--c-purple);
  border-radius: 200px;

  svg {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

/*
 |--------------------------------------------------------------------------
 |
 | Description of what this file is for
 |
 | @package Baukasten
 | @author David Hellmann [david@hellmann.io]
 | .m-quote
 |--------------------------------------------------------------------------
 */

.m-quote {
  font-style: italic;

  footer {
    font-size: 13px;
    font-style: normal;

    @include media('>=s') {
      font-size: 2vw;
    }

    a {
      color: var(--c-white);
      text-decoration: underline;
      font-family: var(--ff-body);
      font-weight: 400;
      font-style: normal;

      &:hover {
        color: var(--c-white);
      }
    }
  }
}

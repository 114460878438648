/*
 |--------------------------------------------------------------------------
 |
 | Description of what this file is for
 |
 | @package Baukasten
 | @author David Hellmann [david@hellmann.io]
 | .m-navMain
 |--------------------------------------------------------------------------
 */


.c-navigation {
  opacity: 0;
  transform: translate3d(0, 0, 0);
  pointer-events: all;
  padding: 2rem;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100%;
  max-width: 100%;

  .js-loaded & {
    opacity: 1;
  }

  .p-home & {
    transform: translate3d(0, 100%, 0);
    pointer-events: none;
    transition: none;
  }

  .p-home.is-introAniStarted & {
    transform: translate3d(0, 0, 0);
    pointer-events: all;
    transition: transform 1s 0.25s var(--ease-out-cubic);

    @include media('>=m') {
      transition: transform 1s 1s var(--ease-out-cubic);
    }
  }
}

.c-navigation__list {
  @include ms(10);
  line-height: 1;
  text-align: right;
  font-weight: 700;

  @include media('>=m') {
    @include ms(7);
  }

  @include media('height<=600px', 'landscape') {
    @include ms(7);
  }
}

.c-navigation__listItem {
  position: relative;

  &:nth-child(4) {
    font-weight: 400;
    @include wf('impact');

    .device-iPad &,
    .device-mobile &,
    .device-tablet & {
      padding-top: 1rem;
      margin-bottom: -1rem;
    }
  }

  &:nth-child(5) {
    font-family: 'Times New Roman', 'Times', serif;
  }

  &:last-child {
    font-style: italic;
  }
}

.c-navigation__listItemLink {
  display: inline-block;

  .is-open--layer &.is-active {
    transform: scaleY(1.1);
  }
}

/*
 |--------------------------------------------------------------------------
 |  Stacks
 |--------------------------------------------------------------------------
 */

/* stylelint-disable */
$bk-stacks: (
    (0\.5),
    (1),
    (1\.5),
    (2),
    (2\.5),
    (3),
    (3\.5),
    (4),
    (4\.5),
    (5),
) !default;
/* stylelint-enable */

// Ratio variants
@each $stack in $bk-stacks {
  .stack-#{$stack} > * + * {
    margin-top: #{$stack}rem;
  }
}


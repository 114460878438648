/* -------------------------------------------------- */
/*    Web Font Loaded
/* -------------------------------------------------- */

@mixin webfont($font) {
  .fonts-loaded & {
    font-family: var(--ff-#{$font});
  }
}

// Shorthand Version
@mixin wf($args...) {
  @include webfont($args...);
}

/*
 |--------------------------------------------------------------------------
 |
 | Description of what this file is for
 |
 | @package Baukasten
 | @author David Hellmann [david@hellmann.io]
 | .gridOverlay
 |--------------------------------------------------------------------------
 */

.js-toggleGrid {
  position: fixed;
  left: 10px;
  bottom: 10px;
  z-index: 111111111111;
  background-color: #111;
  height: 20px;
  padding: 0 6px;
  border-radius: 5px;
  cursor: pointer;
  color: #eee;
  font-family: sans-serif;
  font-size: 11px;
  text-align: center;
  line-height: 22px;
  font-weight: 400;
}

.gridOverlay {
  position: fixed;
  z-index: 1111111;
  top: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  display: none;

  &.is-visible {
    display: block;
  }

  .container {
    position: relative;
    height: 100vh;
    outline: 1px solid rgba(#D81B60, 0.25);
  }

  .row {
    height: 100vh;
  }

  .col {
    position: relative;
    height: 100vh;
    box-shadow: 1px 0px 0px 0px rgba(#4FC3F7, 0.25);
    width: calc(1 / 6 * 100%);

    &:first-child {
      box-shadow: -1px 0px 0px 0px rgba(#4FC3F7, 0.25), 1px 0px 0px 0px rgba(#4FC3F7, 0.25);
    }

    &:nth-child(n+7) {
      display: none;
    }

    @screen m {
      width: calc(1 / 12 * 100%);

      &:nth-child(n+7) {
        display: block;
      }

      &:nth-child(n+13) {
        display: none;
      }
    }

    @screen l {
      width: calc(1 / 24 * 100%);

      &:nth-child(n+7) {
        display: block;
      }
    }

    @screen max {
      width: calc(1 / 24 * 100%);
      display: block !important;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: 100vh;
    }

    &::before {
      left: 1rem;
      top: 0;
      border-right: 1px dotted rgba(#D81B60, 0.25);
    }

    &::after {
      right: 1rem;
      top: 0;
      border-left: 1px dotted rgba(#D81B60, 0.25);
    }
  }
}

/**
 *
 * Project-wide styles
 *
 */

@import "../01-settings/settings.breakpoints";
@import "../01-settings/settings.modular-scale";
@import "../02-tools/tools.mixin.webfont";

[v-cloak] > * {
  display: none !important;
}

[v-cloak]::before {
  content: 'loading…';
}

html {
  /* Min Multiplicator */
  font-size: $bk-spacingMin;
  height: 100%;
  line-height: var(--lh-base);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  overflow: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &.is-open--layer {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  /* Fluid Multiplicator */
  @include media('>=min') {
    font-size: calc(#{$bk-spacingMin} + #{strip-unit($bk-spacingMin)} * ((100vw - #{map_get($breakpoints, min)}) / #{strip-unit(map_get($breakpoints, max))}));
  }

  /* Max Multiplicator */
  @include media('>=max') {
    font-size: $bk-spacingMax;
  }
}

body {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--c-white);
  color: var(--c-black);
  font-family: var(--ff-body);
  font-size: 24px;
  line-height: var(--lh-base);
  font-weight: 400;
  position: relative;
  padding: 0;
  margin: 0;


  @include media('>=s') {
    font-size: 3vw;
  }

  &.no-hover > * {
    pointer-events: none;
  }

  &.is-resizing * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }

  &.body-blocked {
    height: 100vh;
    overflow: hidden;
  }

  &.p-home {
    height: 100vh;
    max-height: 100%;
    overflow: hidden;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  font-style: italic;
  vertical-align: middle;
  outline: none;
  width: 100%;
}

img[data-sizes='auto'] {
  display: block;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 750ms 125ms var(--ease-out-cubic);
}

[loading='lazy'],
.lazypreload {
  opacity: 1;
}

.user-is-tabbing {
  *:focus {
    outline: 2px solid var(--c-gray-500) !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}

.sro {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

code,
kbd,
pre,
samp {
  font-family: var(--ff-mono);
}

::selection {
  background-color: var(--c-gray-900);
  color: var(--c-gray);
}

/* -- No outline for non-keyboard-inputs elements */
button:focus,
select:focus,
textarea:focus,
input:focus {
  outline: none;
}

table {
  width: 100%;
}

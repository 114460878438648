h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-word;
  font-size: 24px;
  font-weight: 400;

  @include media('>=s') {
    font-size: 3vw;
  }
}

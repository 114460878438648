/*
 |--------------------------------------------------------------------------
 | @package  craft-baukasten
 | @author David Hellmann [david@hellmann.io]
 |
 | .o-appFooter
 |--------------------------------------------------------------------------
 */

.c-appFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 0;

  .p-home & {
    transform: translate3d(0, 100%, 0);
    transition: none;
  }

  .p-home.is-introAniStarted & {
    transform: translate3d(0, 0, 0);
    pointer-events: all;
    transition: transform 1s 0.25s var(--ease-out-cubic);

    @include media('>=m') {
      transition: transform 1s 1s var(--ease-out-cubic);
    }
  }
}

.c-appFooter__list {
  justify-content: center;
}

.c-appFooter__listItemLink {
  display: inline-block;
  font-weight: 400;
  padding: 1rem;
  @include ms(-1);

  .is-open--layer & {
    opacity: 0;
  }
}

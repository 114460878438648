/*
 * Border Radius
 */

:root {
  --br-0: 0;
  --br-2: 2px;
  --br-3: 3px;
  --br-5: 5px;
  --br-10: 10px;
  --br-25: 25px;
  --br-50: 50px;
  --br-round: 100vw;
}

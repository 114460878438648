/*
 |--------------------------------------------------------------------------
 |  o-imageGrid
 |--------------------------------------------------------------------------
 */


.c-imageGrid {
  @include row(2);
  z-index: 0;
  user-select: none;
  height: 45vw;
  position: relative;
  max-height: 50vh;

  @include media('>=l') {
    height: 60vw;
  }

  &__item {
    @include col-padding(2);
    position: absolute;
    left: -1vw;
    top: 0;
    flex: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(1)  {
      display: none;

      @include media('>=l') {
        display: block;

        img {
          height: 80vw;
          max-height: 60vh;
        }
      }
    }

    &:nth-child(2) {
      left: 0;

      img {
        height: 60vw;
      }

      @include media('>=l') {
        left: 30vh;
        margin-top: 4vw;

        img {
          height: 70vw;
          max-height: 50vh;
        }
      }
    }

    .c-image {
      flex: none;
      position: relative;
    }

    img {
      max-width: none;
      width: auto;
      pointer-events: none;
    }
  }
}

/*
 |--------------------------------------------------------------------------
 | @package  craft-baukasten
 | @author David Hellmann [david@hellmann.io]
 |
 | .c-layers
 |--------------------------------------------------------------------------
 */

.c-layers {
  h1, h2 {
    position: relative;
    z-index: 100;
    pointer-events: none;
  }

  &__wrapper {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: auto;
    z-index: 1000;
    transition: transform 450ms ease-out, opacity 200ms ease-out;

    &--level1 {
      padding-top: 5rem;

      @include media('>=s') {
        padding-right: 5rem;
      }
    }

    &--level2 {
      padding-top: 4rem;

      @include media('>=s') {
        padding-right: 4rem;
      }
    }
  }

  &__layer {
    position: relative;
    padding: 3rem 3rem 40vh 3rem;
    transition: transform 850ms ease-out, opacity 200ms ease-out;

    @include media('>=s') {
      padding: 3rem 3rem 40vh 3rem;
    }

    &--level1 {
      background-color: var(--c-black);
      color: var(--c-white);
      min-height: calc(100vh);

      .device-mobile &,
      .device-tablet & {
        min-height: 100vh;
      }
    }

    &--level2 {
      background-color: var(--c-white);
      box-shadow: var(--bs-dreamy);
      color: var(--c-black);
      min-height: calc(100vh - 4rem);

      .device-mobile &,
      .device-tablet & {
        min-height: 100vh;
      }
    }
  }

  &__hoverTop {
    position: absolute;
    height: 5rem;
    top: 0;
    right: 0;
    left: 0;

    @include media('>=s') {
      right: 5rem;
      height: 5rem;
    }
  }

  &__hoverRight {
    position: absolute;
    width: 5rem;
    top: 5rem;
    bottom: 0;
    right: 0;

    @include media('>=s') {
      width: 5rem;
      top: 5rem;
    }
  }
}

.c-layers .c-layers__wrapper.is-hidden,
.c-layers .c-layers__wrapper.is-lastLevel:not(.is-shown) {
  opacity: 0;
  transform: translateY(40px);
  pointer-events: none
}

@include media('>=s') {
  .c-layers__wrapper:hover .c-layers__layer:not(:hover) {
    transform: translate(-3rem, 3rem);
  }

  .c-layers__wrapper .c-layers__hoverTop:hover ~ .c-layers__layer:not(:hover) {
    transform: translateY(3rem);
  }

  .c-layers__wrapper .c-layers__hoverRight:hover ~ .c-layers__layer:not(:hover) {
    transform: translateX(-3rem);
  }
}


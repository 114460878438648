/*
 |--------------------------------------------------------------------------
 | @package  craft-baukasten
 | @author David Hellmann [david@hellmann.io]
 |
 | .o-appHeader
 |--------------------------------------------------------------------------
 */



.c-appHeader {
  pointer-events: none;
  transform-origin: left top;
  position: fixed;
  left: 0;
  top: 0;
  padding-left: 2rem;
  padding-top: 2rem;
  opacity: 0;
  font-weight: 700;
  line-height: 1;
  transform: translate3d(0, -100vh, 0);
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;

  .p-home & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: none;
  }

  .p-home.is-introAniStarted & {
    transform: translate3d(0, -100vh, 0);
    transition: transform 1s 0.25s var(--ease-out-cubic);

    @include media('>=m') {
      transition: transform 1s 1s var(--ease-out-cubic);
    }
  }

  &__row {
    display: flex;
  }

  &__word {
    display: inline-flex;
    position: relative;
    font-size: 17vw;

    &--1 {
      font-weight: 400;
      @include wf('impact');
    }

    &--2 {
      margin-right: 0.5ex;
    }

    &--2,
    &--3 {
      font-size: 17vw;
    }
  }

  &__border {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.5vw;
    height: 2vw;
    background-color: var(--c-black);
  }
}

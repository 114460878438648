/* -------------------------------------------------- */
/*    Links
/* -------------------------------------------------- */

a {
  transition: all 200ms ease-out;
  transform-origin: center bottom;
  color: var(--c-black);
  text-decoration: none;

  .c-layers & {
    color: var(--c-purple);
    font-style: italic;
    font-weight: 700;
    font-family: 'Times New Roman', 'Times', serif;
  }

  .c-layers__layer--level1 & {
    &:hover {
      color: var(--c-purple);
    }
  }

  .c-layers__layer--level2 & {
    &:hover {
      color: var(--c-purple);
    }
  }

  &:hover {
    transform: scaleY(1.1);
    color: var(--c-black);
  }
}

/*
 * Typography
 */

:root {
  --ff-headline: 'ImpactURW', 'Impact', 'Haettenschweiler', 'Franklin Gothic Bold', 'Charcoal', 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif;
  --ff-body: 'Arial', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --ff-impact: 'ImpactURW', 'Impact', 'Haettenschweiler', 'Franklin Gothic Bold', 'Charcoal', 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif;
  --ff-mono: 'Roboto Mono', 'Courier New', Courier, monospace;

  --lh-base: 1.4;
  --lh-base-headings: 1.2;
}

/*
 * Breakpoints
 */

$breakpoints: (
  'min': 320px,
  's': 600px,
  'm': 800px,
  'l': 1000px,
  'max': 1600px
);

// Breakpoints
// Important to include "Include Media" at this point
// Cause we set the map our Breakpoints to their map $breakpoints
@import '~include-media/dist/include-media';
$bk-spacingMin: 8px;
$bk-spacingMax: 16px;
$bk-defaultGutter: 1rem;

// Settings
$bk-grid-settings-custom: (
  units: (
    gutter: $bk-defaultGutter,
  ),
  breakpoints: (
    min: map_get($breakpoints, min),
    s: map_get($breakpoints, s),
    m: map_get($breakpoints, m),
    l: map_get($breakpoints, l),
    max: map_get($breakpoints, max)
  )
);

@import '~baukasten-grid/scss/functions';
@import '~baukasten-grid/scss/grid';
@import '~baukasten-grid/scss/variables';
@import '~baukasten-grid/scss/mixins';
